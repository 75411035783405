import { productsMain } from 'helpers/products';
import { CartProductData } from 'interfaces/Cart/Cart';

interface IdData {
  id: number;
  stageId: number;
}

interface CartProducts extends CartProductData {
  filters?: string[];
}

export const isProduction = host => {
  return host === 'https://idchess.com' || host === 'https://demo.idchess.com';
};

export const idForTranslate = id => {
  if (isProduction(process.env.NEXT_PUBLIC_API_HOST)) {
    return id;
  }

  const currentProduct = productsMain.filter(item => item.stageId === id)[0];

  return currentProduct?.id;
};

export const useValidProductId = (host: string, data: IdData) => {
  return isProduction(host) ? data.id : data.stageId;
};

export const useGetProductsWithFilters = orderDataStored => {
  const products: CartProducts[] = orderDataStored?.order_packs?.map(item => {
    const currentId = idForTranslate(item?.pack_id);
    const filters = productsMain.filter(elem => elem.id === currentId)[0];

    return {
      filters: filters.filters,
      size: filters.sizes ? `products.items.${currentId}.size` : null,
      ...item,
    };
  });

  return products;
};
