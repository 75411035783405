import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartData } from 'interfaces/Cart/Cart';

export interface IOrderStore {
  orderData?: CartData;
  userInfo?: any;
}

const initialState: IOrderStore = {
  orderData: {},
  userInfo: {},
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderData(state, action: PayloadAction<any>): void {
      let data = action.payload;
      if (data?.order_packs) {
        data = {
          ...data,
          order_packs: data?.order_packs.sort((a, b) => a.id - b.id),
        };
      }
      state.orderData = data;
    },
    setUserInfo(state, action: PayloadAction<any>): void {
      state.userInfo = action.payload;
    },
  },
});

const { reducer: orderReducer, actions: orderActions } = orderSlice;

export const { setOrderData, setUserInfo } = orderActions;

export default orderReducer;
