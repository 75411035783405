import { ProductCardInterface } from 'interfaces/Product/ProductItem';

export const productCookie = 'idchess_order';

export const productsMain = [
  {
    id: 4,
    stageId: 16,
    image: '12-months.jpg',
    filters: ['all', 'licenses'],
  },
  {
    id: 2,
    stageId: 14,
    image: '1-month.jpg',
    filters: ['all', 'licenses'],
  },
  {
    id: 3,
    stageId: 15,
    image: '6-months.jpg',
    filters: ['all', 'licenses'],
  },
  {
    id: 6,
    stageId: 17,
    image: 'box-cover.jpg',
    filters: ['all', 'box'],
  },
  {
    id: 5,
    stageId: 18,
    image: 'box-chess-cover.jpg',
    filters: ['all', 'box'],
    amazon: 'https://www.amazon.com/dp/B0BM51N7Y3?ref=myi_title_dp',
  },
  {
    id: 16,
    stageId: 38,
    image: 'tripod-cover.jpg',
    filters: ['all', 'box'],
  },
  {
    id: 7,
    stageId: 24,
    image: 'cup-keep-1.jpg',
    filters: ['all', 'merch'],
  },
  /* products page headers and filters, full header */
  /* limit news items lines to 3 */
  {
    id: 8,
    stageId: 30,
    image: 'shirt-black-1.jpg',
    filters: ['all', 'merch'],
    sizes: [8, 9],
  },
  {
    id: 9,
    stageId: 31,
    image: 'shirt-white-1.jpg',
    filters: ['all', 'merch'],
    hideInCatalog: true,
    sizes: [8, 9],
  },
  {
    id: 10,
    stageId: 32,
    image: 'shirt-white-1.jpg',
    filters: ['all', 'merch'],
    sizes: [10, 11],
  },
  {
    id: 11,
    stageId: 33,
    image: 'bag-play.jpg',
    filters: ['all', 'merch'],
    hideInCatalog: true,
    sizes: [10, 11],
  },
  {
    id: 12,
    stageId: 34,
    image: 'bag-play-main.jpg',
    filters: ['all', 'merch'],
  },
  {
    id: 13,
    stageId: 25,
    image: 'bag-chess-1.jpg',
    filters: ['all', 'merch'],
  },
  {
    id: 14,
    stageId: 36,
    image: 'gift-calm-1.jpg',
    filters: ['all', 'box'],
  },
  {
    id: 15,
    stageId: 37,
    image: 'gift-calm2-1.jpg',
    filters: ['all', 'box'],
  },
  {
    id: 17,
    stageId: 39,
    image: 'postcard-1.jpg',
    filters: ['all', 'merch'],
  },
  {
    id: 18,
    stageId: 28,
    image: 'postcard-win-1.jpg',
    filters: ['all', 'merch'],
  },
  {
    id: 19,
    stageId: 27,
    image: 'backpack-1.jpg',
    filters: ['all', 'merch'],
    notAvaliable: true,
  },
];

export const productsMainUs = [
  {
    id: 4,
    stageId: 16,
    image: '12-months.jpg',
    filters: ['all', 'licenses'],
  },
  {
    id: 2,
    stageId: 14,
    image: '1-month.jpg',
    filters: ['all', 'licenses'],
  },
  {
    id: 3,
    stageId: 15,
    image: '6-months.jpg',
    filters: ['all', 'licenses'],
  },
  {
    id: 5,
    stageId: 18,
    image: 'box-chess-cover.jpg',
    filters: ['all', 'box'],
    amazon: 'https://www.amazon.com/dp/B0BM51N7Y3?ref=myi_title_dp',
  },
];

export const productsMainOthers = [
  {
    id: 4,
    stageId: 16,
    image: '12-months.jpg',
    filters: ['all', 'licenses'],
  },
  {
    id: 2,
    stageId: 14,
    image: '1-month.jpg',
    filters: ['all', 'licenses'],
  },
  {
    id: 3,
    stageId: 15,
    image: '6-months.jpg',
    filters: ['all', 'licenses'],
  },
];

export const productPrices = {
  ru: {
    4: {
      price: 2990,
    },
    2: {
      price: 499,
    },
    3: {
      price: 1990,
    },
    6: {
      price: 3490,
    },
    5: {
      price: 3990,
    },
    7: {
      price: 690,
      discountPrice: 490,
    },
    8: {
      price: 1290,
      discountPrice: 990,
    },
    9: {
      price: 1290,
      discountPrice: 990,
    },
    10: {
      price: 1390,
      discountPrice: 1190,
    },
    11: {
      price: 1390,
      discountPrice: 1190,
    },
    12: {
      price: 690,
      discountPrice: 490,
    },
    13: {
      price: 790,
      discountPrice: 590,
    },
    14: {
      price: 3690,
      discountPrice: 3190,
    },
    15: {
      price: 2390,
      discountPrice: 1190,
    },
    16: {
      price: 890,
      discountPrice: 500,
    },
    17: {
      price: 120,
      discountPrice: 50,
    },
    18: {
      price: 120,
      discountPrice: 50,
    },
    19: {
      price: 790,
    },
  },
  en: {
    3: {
      price: 35,
    },
    2: {
      price: 9,
    },
    4: {
      price: 54,
    },
    6: {
      price: 79,
    },
    5: {
      price: 79,
    },
  },
};

export const productsImages = {
  2: [
    'license-1.jpg',
    'license-2.jpg',
    'license-3.jpg',
    'license-4.jpg',
    'license-5.jpg',
    'license-6.jpg',
  ],
  3: [
    'license-1.jpg',
    'license-2.jpg',
    'license-3.jpg',
    'license-4.jpg',
    'license-5.jpg',
    'license-6.jpg',
  ],
  4: [
    'license-1.jpg',
    'license-2.jpg',
    'license-3.jpg',
    'license-4.jpg',
    'license-5.jpg',
    'license-6.jpg',
  ],
  6: ['box-1.jpg', 'box-4.jpg', 'box-5.jpg'],
  5: ['box-chess.jpg', 'box-3.jpg', 'box-4.jpg', 'box-5.jpg'],
  7: [
    'cup-keep-1.jpg',
    'cup-keep-2.jpg',
    'cup-keep-3.jpg',
    'cup-keep-4.jpg',
    'cup-keep-5.jpg',
  ],
  8: [
    'shirt-black-1.jpg',
    'shirt-black-2.jpg',
    'shirt-black-3.jpg',
    'shirt-black-4.jpg',
  ],
  9: [
    'shirt-black-1.jpg',
    'shirt-black-2.jpg',
    'shirt-black-3.jpg',
    'shirt-black-4.jpg',
  ],
  10: [
    'shirt-white-1.jpg',
    'shirt-white-2.jpg',
    'shirt-white-3.jpg',
    'shirt-white-4.jpg',
  ],
  11: [
    'shirt-white-1.jpg',
    'shirt-white-2.jpg',
    'shirt-white-3.jpg',
    'shirt-white-4.jpg',
  ],
  12: ['bag-play.jpg'],
  13: [
    'bag-chess-1.jpg',
    'bag-chess-2.jpg',
    'bag-chess-3.jpg',
    'bag-chess-4.jpg',
    'bag-chess-5.jpg',
  ],
  14: ['gift-calm-2.jpg', 'gift-calm-1.jpg', 'gift-calm-4.jpg'],
  15: [
    'gift-calm2-1.jpg',
    'gift-calm2-2.jpg',
    'gift-calm2-3.jpg',
    'gift-calm2-4.jpg',
  ],
  16: [
    'tripod-5.jpg',
    'tripod-1.jpg',
    'tripod-2.jpg',
    'tripod-3.jpg',
    'tripod-4.jpg',
  ],
  17: ['postcard-1.jpg', 'postcard-3.jpg'],
  18: ['postcard-win-1.jpg'],
  19: [
    'backpack-1.jpg',
    'backpack-2.jpg',
    'backpack-3.jpg',
    'backpack-4.jpg',
    'backpack-5.jpg',
  ],
};

export const currency = {
  ru: {
    sign: '₽',
    code: 'RUB',
  },
  en: {
    sign: '$',
    code: 'USD',
  },
};

export const amazonLinkMain =
  'https://www.amazon.com/dp/B0BM51N7Y3?ref=myi_title_dp';

export const getProduct = id => {
  const currentItem = productsMain.filter(item => item.id.toString() === id)[0];

  if (currentItem) {
    const data: ProductCardInterface = {
      ...currentItem,
      images: productsImages[currentItem.id],
      amazon: currentItem.amazon ? currentItem.amazon : '',
    };

    if (currentItem.filters.indexOf('licenses') !== -1) {
      const terms = productsMain.filter(
        item => item.filters.indexOf('licenses') !== -1
      );
      data.terms = terms.map(item => item.id);
    }

    return {
      status: 200,
      data: data,
    };
  }

  return {
    status: 404,
  };
};
