import { AxiosError, AxiosResponse } from 'axios';
import { httpClient } from 'helpers/httpClient';
import { NoAnswer } from 'interfaces/Feedback/Feedback';
import { CartData } from 'interfaces/Cart/Cart';
import { useGetCurrentToken } from 'helpers/hooks/sendCurrentToken';

interface ResponseCartData extends CartData, NoAnswer {}

interface ResponsePromocodeData extends NoAnswer {
  quantity_discount_in_currency: number;
  promocode_discount?: number;
  base_price: number;
  total_price: number;
  quantity: number;
  promocode_discount_in_currency?: string;
  dealer_id?: number;
}

export const getCurrentCart = async (
  data
): Promise<AxiosResponse<ResponseCartData>> => {
  try {
    const config = {
      headers: useGetCurrentToken(),
      params: data,
    };

    const response = await httpClient.get<ResponseCartData>(
      '/cart_items',
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const changeCart = async (
  data
): Promise<AxiosResponse<ResponseCartData>> => {
  try {
    const config = {
      headers: useGetCurrentToken(),
      params: {
        locale: data.locale,
        geo: data.geo,
      },
    };

    const response = await httpClient.patch<ResponseCartData>(
      '/cart_items/change',
      data.data,
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const deleteAllCart = async (data): Promise<AxiosResponse<NoAnswer>> => {
  try {
    const config = {
      headers: useGetCurrentToken(),
      params: {
        locale: data.locale,
      },
    };

    const response = await httpClient.delete<NoAnswer>('/cart_items', config);

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const addPromocode = async (
  data
): Promise<AxiosResponse<ResponsePromocodeData>> => {
  try {
    const config = {
      headers: useGetCurrentToken(),
      params: {
        locale: data.locale,
        geo: data.geo,
      },
    };

    const response = await httpClient.post<ResponsePromocodeData>(
      '/promocode',
      data.data,
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const removePromocode = async (
  data
): Promise<AxiosResponse<ResponsePromocodeData>> => {
  try {
    const config = {
      headers: useGetCurrentToken(),
      data: {
        geo: data.geo,
      },
    };

    const response = await httpClient.delete<ResponsePromocodeData>(
      '/promocode',
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const handleGetCart = async ({
  language,
  geo,
  successCallback,
  errorCallback,
}) => {
  const finalData = {
    locale: language,
    geo: geo,
  };

  const response = await getCurrentCart(finalData);

  if (!response?.status || !response.data) {
    errorCallback();
    return;
  }

  switch (response.status) {
    case 200:
      successCallback(response.data);
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const handleChangeCart = async ({
  language,
  geo,
  id,
  quantity,
  successCallback,
  errorCallback,
}) => {
  const finalData = {
    locale: language,
    geo: geo,
    data: {
      id: id,
      quantity: quantity,
    },
  };

  const response = await changeCart(finalData);

  if (!response?.status || !response.data) {
    errorCallback();
    return;
  }

  switch (response.status) {
    case 200:
      successCallback(response.data);
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const handleDeleteAllCart = async ({
  language,
  geo,
  successCallback,
  errorCallback,
}) => {
  const finalData = {
    locale: language,
    geo: geo,
  };

  const response = await deleteAllCart(finalData);

  switch (response.status) {
    case 200:
      successCallback();
      break;

    case 204:
      successCallback();
      break;

    default:
      errorCallback && errorCallback(response.data);
      break;
  }
};

export const handleAddPromocode = async (
  data,
  actions,
  { language, geo, promocode, successCallback, errorCallback }
) => {
  const { setSubmitting } = actions;
  const finalData = {
    locale: language,
    geo: geo,
    data: {
      promo_code: promocode,
    },
  };

  const response = await addPromocode(finalData);
  setSubmitting(false);

  if (!response?.status || !response.data) {
    errorCallback();
    return;
  }

  switch (response.status) {
    case 201:
      successCallback(response.data);
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const handleDeletePromocode = async ({
  language,
  geo,
  successCallback,
  errorCallback,
}) => {
  const finalData = {
    locale: language,
    geo: geo,
  };

  const response = await removePromocode(finalData);

  if (!response?.status || !response.data) {
    errorCallback();
    return;
  }

  switch (response.status) {
    case 200:
      successCallback(response.data);
      break;

    default:
      errorCallback(response.data);
      break;
  }
};
